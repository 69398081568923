import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import Alert from '../../components/Alert'
import { IoIosSearch } from 'react-icons/io';

import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaRegFileExcel } from 'react-icons/fa6';
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import Loading from '../../components/Loading';
import AddDepense from './AddDepense';
import ActionButton from '../../components/ActionButton';
import Swal from 'sweetalert2';
import { FiShoppingCart } from 'react-icons/fi';


const Depenses = () => {
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(false);
    const [depenses, setDepenses] = useState([]);
    const [showAddDepense, setShowAddDepense] = useState(false);
    const { token, setToken, user } = useStateContext();
    const tableRef = useRef();
    const [req, setReq] = useState({
        DateDebut: '',
        DateFin: '',
        exp: '',
    });
    const navigate = useNavigate();

    const supprimer = (depense) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/depense/' + depense.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getDepenses();
                    }).catch(err => {
                        const response = err.response;
                        if (err.response.status === 401) {
                            setToken(null);
                        } else {
                            if (response.status === 401) {
                                setToken(null);
                            }
                            else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }
                        }

                    })
            }
        });
    }

    useEffect(() => {

    }, [])


    const closeAddDepense = () => {
        setShowAddDepense(false);
    }
    const getDepenses = () => {
        setLoading(true);
        axiosClient.get('/getDepenses?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin + '&exp=' + req.exp)
            .then(response => {
                setDepenses(response.data.depenses);
                setLoading(false);
                setErrors(null);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }


    return (
        <>
            <Header icon={<FiShoppingCart />} title="Depenses" />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center'>
                <Input label="Date debut" type="date" value={req.DateDebut} onChange={ev => setReq({ ...req, DateDebut: ev.target.value })} required="required" />
                <Input label="Date fin" type="date" value={req.DateFin} onChange={ev => setReq({ ...req, DateFin: ev.target.value })} required="required" />
                <Input label="Expression" type="text" onChange={ev => setReq({ ...req, exp: ev.target.value })} />
                <div className=' flex gap-2 mt-8'>
                    <Button onClick={() => getDepenses()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
                    <DownloadTableExcel
                        filename="Depenses"
                        sheet="depenses"
                        currentTableRef={tableRef.current}
                    >
                        <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

                    </DownloadTableExcel>
                </div>
            </div>
            {
                user.role && user.role.name === 'depense' ? <div className="flex my-4">
                    <Button onClick={() => setShowAddDepense(true)} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
                </div>
                    : <></>
            }
            <div className="flex my-2">
                <h1 className='text-red-500 font-bold'>Total: {depenses.reduce((n, {montant}) => n + parseFloat(montant), 0)} MRU</h1>
            </div>
            <div className='flex mt-3 overflow-auto'>
                <table ref={tableRef} className='table-auto' >
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Categorie</th>
                            <th>Titre</th>
                            <th>Beneficiaire</th>
                            <th>Montant</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="6"><Loading /></td></tr></tbody> :
                        <tbody>
                            {depenses.map(o => (
                                <tr>
                                    <td>{o.dateDepense}</td>
                                    <td>{o.categorie}</td>
                                    <td>{o.titre}</td>
                                    <td>{o.beneficiaire}</td>
                                    <td>{o.montant}</td>
                                    <td><ActionButton onClick={()=>{ supprimer(o) }} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Supprimer" /></td>
                                </tr>
                            ))

                            }
                        </tbody>
                    }

                </table>
            </div>
            {
                showAddDepense ? <AddDepense onClose={closeAddDepense} /> : <></>
            }

        </>
    )
}

export default Depenses